import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style/base.css'
import '@/assets/style/iconfont.css'
import '@/assets/style/font.css'
import wxShare from '@/components/wxShare.js'

createApp(App).use(store).use(router).mount('#app')
wxShare()
