import axios from 'axios'

const baseURL = 'https://applet.iif2f.com'

axios.defaults.baseURL = baseURL

export function get (url, params) {
  return axios.get(url, {
    params
  })
}

export function postJson (url, data) {
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function postForm (url, data) {
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
