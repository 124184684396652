import { postJson } from '../service/base'
import wx from 'weixin-js-sdk'

export default function wxShare () {
  const promise = postJson('/home/freeuser/queryWxShareParam', {
    webUrl: 'http://mob.iif2f.com'
  })
  promise.then(res => {
    if (res.data.code === 200) {
      const result = res.data.data
      const appId = result.appId
      const timestamp = result.timestamp
      const nonceStr = result.nonceStr
      const signature = result.signature
      const apiList = ['updateAppMessageShareData', 'updateTimelineShareData']
      wx.config({
        debug: false,
        appId: appId,
        timestamp: timestamp,
        nonceStr: nonceStr,
        signature: signature,
        jsApiList: apiList
      })
    } else {
      console.log('res.data.message')
    }
  })
  wx.ready(() => {
    const imgUrl = '@/assets/img/指定地球村.png'
    const linkStr = 'http://mob.iif2f.com'
    wx.updateAppMessageShareData({
      title: '全购网路有限公司',
      desc: '全购云 专业的B2B2C商城服务技术＋运营商',
      link: linkStr,
      imgUrl: imgUrl
    })
    wx.updateTimelineShareData({
      title: '全购网路有限公司',
      desc: '全购云 专业的B2B2C商城服务技术＋运营商',
      link: linkStr,
      imgUrl: imgUrl
    })
  })
}
