import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  }, {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  }, {
    path: '/newuser',
    name: 'Newuser',
    component: () => import(/* webpackChunkName: "newuser" */ '../views/newuser.vue')
  }, {
    path: '/verification',
    name: 'Verification',
    component: () => import(/* webpackChunkName: "verification" */ '../views/verification.vue')
  }, {
    path: '/read',
    name: 'Read',
    component: () => import(/* webpackChunkName: "read" */ '../views/read.vue')
  }, {
    path: '/nowreserve',
    name: 'Nowreserve',
    component: () => import(/* webpackChunkName: "nowreserve" */ '../views/nowreserve.vue')
  }, {
    path: '/page/1',
    name: 'Page1',
    component: () => import(/* webpackChunkName: "Page1" */ '../views/page1.vue')
  }, {
    path: '/page/2',
    name: 'Page2',
    component: () => import(/* webpackChunkName: "Page2" */ '../views/page2.vue')
  }, {
    path: '/page/3',
    name: 'Page3',
    component: () => import(/* webpackChunkName: "Page3" */ '../views/page3.vue')
  }, {
    path: '/page/4',
    name: 'Page4',
    component: () => import(/* webpackChunkName: "Page4" */ '../views/page4.vue')
  }, {
    path: '/page/5',
    name: 'Page5',
    component: () => import(/* webpackChunkName: "Page5" */ '../views/page5.vue')
  }, {
    path: '/page/6',
    name: 'Page6',
    component: () => import(/* webpackChunkName: "Page6" */ '../views/page6.vue')
  },
  {
    path: '/page/7',
    name: 'Page7',
    component: () => import(/* webpackChunkName: "Page7" */ '../views/page7.vue')
  }, {
    path: '/page/8',
    name: 'Page8',
    component: () => import(/* webpackChunkName: "Page8" */ '../views/page8.vue')
  }, {
    path: '/page/9',
    name: 'Page9',
    component: () => import(/* webpackChunkName: "Page9" */ '../views/page9.vue')
  }, {
    path: '/page/10',
    name: 'Page10',
    component: () => import(/* webpackChunkName: "Page10" */ '../views/page10.vue')
  }, {
    path: '/case/:id',
    name: 'Case',
    component: () => import(/* webpackChunkName: "login" */ '../views/case.vue')
  }, {
    path: '/more',
    name: 'More',
    component: () => import(/* webpackChunkName: "login" */ '../views/more.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  }
})

export default router
